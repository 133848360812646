<template>
  <div class="container mt-1">
    <b-table hover 
            :filter="filter"
            :fields="fields"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            >
        <template #cell(id)="data" >
          <i>{{data.item.id}} </i>
        </template>
        <template #cell(customer)="data">
          <i>{{data.item.customer}} </i>
        </template>
        <template #cell(store)="data">
          <i>{{data.item.store}} </i>
        </template>
        <template #cell(total)="data">
          <i> Q {{data.item.total.toFixed(2)}} </i>
        </template>
        <template #cell(status)="data">
          <i> {{data.item.status==1 ? 'Pendiente de aprobación': data.item.status==2 ? 'para Paquetería'
             : data.item.status==3 ? 'Listo para envío/entrega' : data.item.status==4 ? 'Enviado ' : 'Entregado'}} </i>
        </template>
        <template #cell(actions)="data">
            <router-link class="btn btn-light shadow-sm" :to="`/orders/details/${data.item.id}`">
                <b-icon-clipboard class="text-info"/>
            </router-link>
        </template>

    </b-table>

    <div class="overflow-auto">
        <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"        
        aria-controls="my-table"
        ></b-pagination>
    </div>

  </div>
</template>

<script>
export default {
  components: {
    // FormVue,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      filter: null,

      table: "orders",
      singleTable: "order",
      tableSpanish: "Pedido",
      store: {},
      fields: [
        { key: "id", label: "# Orden" },
        { key: "customer", label: "Cliente" },
        { key: "store", label: "Tienda" },
        { key: "status", label: "Estado" },
        { key: "total", label: "Total" },
        { key: "actions", label: "Detalles" },
      ],
      items: [],
      selectedItem: {},

      showDetails: false,

      method: "post",
      user: null,
    };
  },
  async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.user = JSON.parse(localStorage.getItem("user"));
        let path = "getAllCustom/"+ this.user.id +"/1"
        this.items = await this.$store.dispatch("getAll", path);
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>

<style>
</style>