<template>
  <div>
    <div :style="`height:40vh; background:url(https://vea.global/wp-content/uploads/2020/08/Comunidad-Andina-digitaliza-tramites-para-facilitar-comercio-de-productos-cosmeticos.png); background-size:cover; margin-top:-100px`">
    </div>    
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="title">
          ZONA DE CLIENTES
        </div>
        <hr class="title-line">
      </div>  

      <!-- <button class="btn btn-dark" style="float:right; margin-top:-30px" @click="logout()">
        <b-icon-power /> Cerrar Sesión
      </button>  -->


    </div>

    <div class="container">
        <div>
          <b-card id="tabcontainer">
            <b-tabs >
              <b-tab title="Historial de pedidos" class="hq" active>
                <b-card-text>
                  <orders/>
                </b-card-text>
              </b-tab>
              <b-tab  title="Actualizar datos">
                <!-- <h3 class="text-center">Actualizar datos</h3> -->
                  <!-- contraseña, telefono de contacto, direccion de entrega   -->
                  <div class="row mt-3">
                      <div class="offset-md-3 col-md-6">
                          <label>Teléfono de contacto</label> <br>
                          <input v-model="userData.phone" type="text" class="form-control" placeholder="" >
                      </div>
                      <div class="offset-md-3 col-md-6 mt-3">
                          <label>Dirección de entrega</label> <br>
                          <input v-model="userData.address" type="text" class="form-control" placeholder="" >
                      </div>
                      <div class="offset-md-3 col-md-6 mt-3">
                          <label>Contraseña</label> <br>  
                          <input v-model="userData.password" type="password" class="form-control" placeholder="" >
                      </div>
                      <div class="offset-md-3 col-md-6 mt-3">
                          <label>Repetir contraseña</label> <br>
                          <input v-model="userData.confirmPassword" type="password" class="form-control" placeholder="" >
                      </div>
                      <div class="offset-md-3 col-md-6 mt-5">
                          <input type="button" @click="update()" class="form-control btn bg-primary text-light" value="Actualizar información" />
                      </div>
                  </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
        
    </div>


  </div>
</template>

<script>
import Orders from "../views/Admin/Orders";
// import Orders from './Admin/Orders.vue'

export default {
  components: {
    Orders,
  },
  data() {
    return {
      singleTable: "customers",
      userData: {
        phone: "",
        address: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  Ordersdata() {
    return {};
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user")) || null;
    this.userId = user.id;
    this.userData.phone = user.phone;
    this.userData.address = user.address;
  },
  methods: {
    async update() {
      try {
        delete this.userData.confirmPassword;
        if (this.userData.password === null || this.userData.password == "") {
          delete this.userData.password;
        }
        const result = await this.$store.dispatch("update", {
          table: "customer",
          data: this.userData,
          id: this.userId,
        });
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos actualizados!",
        });
        // this.showDetails = false;
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error!:" + error,
        });
        // alert(error);
      }
    },
  },
};
</script>


<style scoped lang="scss">
#tabcontainer .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000 !important;
}
</style>